<template>
  <div class="view">
    <div style="height: 20vh;background: #e9c7a0;display: flex">
      <div
          style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
          @click="$router.back()">
        <img v-if="$route.query && $route.query.showTitle" src="../../assets/images/back.png"
             style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('皮肤自由基')">
        <div class="view_content_bg_item_title">
          <span>皮肤自由基</span><span>{{ skin1Index }}分</span><span>/100</span><span>{{ skin1 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin1" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('皮肤胶原蛋白')">
        <div class="view_content_bg_item_title">
          <span>皮肤胶原蛋白</span><span>{{ skin2Index }}分</span><span>/100</span><span>{{ skin2 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin2" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤油脂')">
        <div class="view_content_bg_item_title">
          <span>皮肤油脂</span><span>{{ skin3Index }}分</span><span>/100</span><span>{{ skin3 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin3" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤免疫')">
        <div class="view_content_bg_item_title">
          <span>皮肤免疫</span><span>{{ skin4Index }}分</span><span>/100</span><span>{{ skin4 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin4" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤水分')">
        <div class="view_content_bg_item_title">
          <span>皮肤水分</span><span>{{ skin5Index }}分</span><span>/100</span><span>{{ skin5 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin5" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤水分流失量')">
        <div class="view_content_bg_item_title">
          <span>皮肤水分流失量</span><span>{{ skin6Index }}分</span><span>/100</span><span>{{ skin6 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin6" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤血红丝')">
        <div class="view_content_bg_item_title">
          <span>皮肤血红丝</span><span>{{ skin7Index }}分</span><span>/100</span><span>{{ skin7 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin7" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤弹性')">
        <div class="view_content_bg_item_title">
          <span>皮肤弹性</span><span>{{ skin8Index }}分</span><span>/100</span><span>{{ skin8 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin8" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('皮肤黑色素')">
        <div class="view_content_bg_item_title">
          <span>皮肤黑色素</span><span>{{ skin9Index }}分</span><span>/100</span><span>{{ skin9 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin9" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('皮肤角质')">
        <div class="view_content_bg_item_title">
          <span>皮肤角质</span><span>{{ skin10Index }}分</span><span>/100</span><span>{{ skin10 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="skin10" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'

export default {
  name: 'ReportI',
  components: { view_bottom },
  data() {
    return {
      skin1: '',
      skin2: '',
      skin3: '',
      skin4: '',
      skin5: '',
      skin6: '',
      skin7: '',
      skin8: '',
      skin9: '',
      skin10: '',
      skin1Index: '',
      skin2Index: '',
      skin3Index: '',
      skin4Index: '',
      skin5Index: '',
      skin6Index: '',
      skin7Index: '',
      skin8Index: '',
      skin9Index: '',
      skin10Index: '',

    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/getskin?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.skin1 = result.data.skin1
            this.skin2 = result.data.skin2
            this.skin3 = result.data.skin3
            this.skin4 = result.data.skin4
            this.skin5 = result.data.skin5
            this.skin6 = result.data.skin6
            this.skin7 = result.data.skin7
            this.skin8 = result.data.skin8
            this.skin9 = result.data.skin9
            this.skin10 = result.data.skin10
            this.skin1Index = result.data.skin1Index
            this.skin2Index = result.data.skin2Index
            this.skin3Index = result.data.skin3Index
            this.skin4Index = result.data.skin4Index
            this.skin5Index = result.data.skin5Index
            this.skin6Index = result.data.skin6Index
            this.skin7Index = result.data.skin7Index
            this.skin8Index = result.data.skin8Index
            this.skin9Index = result.data.skin9Index
            this.skin10Index = result.data.skin10Index
            this.initBarChart('skin1', this.skin1)
            this.initBarChart('skin2', this.skin2)
            this.initBarChart('skin3', this.skin3)
            this.initBarChart('skin4', this.skin4)
            this.initBarChart('skin5', this.skin5)
            this.initBarChart('skin6', this.skin6)
            this.initBarChart('skin7', this.skin7)
            this.initBarChart('skin8', this.skin8)
            this.initBarChart('skin9', this.skin9)
            this.initBarChart('skin10', this.skin10)

          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },

        },
        yAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    { offset: 0, color: '#dc6666' },                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    { offset: 1, color: '#dc6666' },                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      }
      jiankang.setOption(option)
    },
    openValue(info) {
      //this.$router.push({ path: '/Healthy/Introduce', query: { userId: info, date: new Date().getTime() } })
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: #f14848;
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: #dc6666;
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
