import Login from '../pages/login/login.vue'
import Page404 from '../pages/page404/index.vue'
import error from '../pages/toast/error'
import success from '@/pages/toast/success'
import Index from '@/pages/login/Index'
import Details from '@/pages/login/Details'
import Healthy from '@/pages/healthy/healthy'
import Glossoscopy from '@/pages/healthy/Glossoscopy'
import Face from '@/pages/healthy/Face'
import Sound from '@/pages/healthy/Sound'
import Pulse from '@/pages/healthy/Pulse'
import PhysiologicalAge from '@/pages/healthy/PhysiologicalAge'
import Healthymind from '@/pages/healthy/Healthymind'
import HealthServices from '@/pages/healthy/HealthServices'
import ReportA from '@/pages/healthy/ReportA'
import ReportB from '@/pages/healthy/ReportB'
import ReportC from '@/pages/healthy/ReportC'
import ReportD from '@/pages/healthy/ReportD'
import ReportE from '@/pages/healthy/ReportE'
import ReportF from '@/pages/healthy/ReportF'
import ReportG from '@/pages/healthy/ReportG'
import ReportH from '@/pages/healthy/ReportH'
import ReportI from '@/pages/healthy/ReportI'
import ReportJ from '@/pages/healthy/ReportJ'
import ReportK from '@/pages/healthy/ReportK'
import ReportNan from '@/pages/healthy/ReportNan'
import ReportNv from '@/pages/healthy/ReportNv'
import Introduce from '@/pages/healthy/Introduce'
import jieshao from '../pages/login/jieshao'
import jieshao1 from '../pages/login/jieshao1'

const routes = [
  {
    path: '/Details',
    component: Details,
    meta: {
      title: '整体检测报告+干预方案'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Healthy',//健康报告
    name: 'Healthy',
    component: Healthy,
    meta: {
      title: '健康报告'
    }
  },
  {
    path: '/Healthy/Glossoscopy',//舌诊评估
    name: 'Glossoscopy',
    component: Glossoscopy,
    meta: {
      title: '舌诊报告'
    }
  },
  {
    path: '/Healthy/Face',//舌诊评估
    name: 'Face',
    component: Face,
    meta: {
      title: '面诊报告'
    }
  },
  {
    path: '/Healthy/Pulse',//脉诊检测
    name: 'Pulse',
    component: Pulse,
    meta: {
      title: '脉诊报告'
    }
  },
  {
    path: '/Healthy/Sound',//声强检测
    name: 'Sound',
    component: Sound,
    meta: {
      title: '声强报告'
    }
  },
  {
    path: '/Index',
    component: Details
  },
  {
    path: '/',
    component: Details
  },
  {
    path: '*',
    component: Page404 //404页面
  },
  {
    path: '/error',
    component: error
  },
  {
    path: '/success',
    component: success
  },
  {
    path: '/Healthy/PhysiologicalAge',
    name: 'PhysiologicalAge',
    component: PhysiologicalAge,
    meta: {
      title: '生理年龄'
    }
  },
  {
    path: '/Healthy/Healthymind',
    name: 'Healthymind',
    component: Healthymind,
    meta: {
      title: '身心状况'
    }
  },
  {
    path: '/Healthy/HealthServices',
    name: 'HealthServices',
    component: HealthServices,
    meta: {
      title: '健康服务'
    }
  },
  {
    path: '/Healthy/ReportA',
    name: 'ReportA',
    component: ReportA,
    meta: {
      title: '心脑血管'
    }
  },
  {
    path: '/Healthy/ReportB',
    name: 'ReportB',
    component: ReportB,
    meta: {
      title: '肠胃'
    }
  },
  {
    path: '/Healthy/ReportC',
    name: 'ReportC',
    component: ReportC,
    meta: {
      title: '肝胆'
    }
  },
  {
    path: '/Healthy/ReportD',
    name: 'ReportD',
    component: ReportD,
    meta: {
      title: '肾脏'
    }
  },
  {
    path: '/Healthy/ReportE',
    name: 'ReportE',
    component: ReportE,
    meta: {
      title: '呼吸'
    }
  },
  {
    path: '/Healthy/ReportF',
    name: 'ReportF',
    component: ReportF,
    meta: {
      title: '内分泌'
    }
  },
  {
    path: '/Healthy/ReportG',
    name: 'ReportG',
    component: ReportG,
    meta: {
      title: '免疫'
    }
  },
  {
    path: '/Healthy/ReportH',
    name: 'ReportH',
    component: ReportH,
    meta: {
      title: '骨骼'
    }
  },
  {
    path: '/Healthy/ReportI',
    name: 'ReportI',
    component: ReportI,
    meta: {
      title: '皮肤'
    }
  },
  {
    path: '/Healthy/ReportJ',
    name: 'ReportJ',
    component: ReportJ,
    meta: {
      title: '眼部'
    }
  },
  {
    path: '/Healthy/ReportK',
    name: 'ReportK',
    component: ReportK,
    meta: {
      title: '维生素'
    }
  },
  {
    path: '/Healthy/ReportNan',
    name: 'ReportNan',
    component: ReportNan,
    meta: {
      title: '男科'
    }
  },
  {
    path: '/Healthy/ReportNv',
    name: 'ReportNv',
    component: ReportNv,
    meta: {
      title: '女性'
    }
  },
  {
    path: '/Healthy/Introduce',
    name: 'Introduce',
    component: Introduce,
    meta: {
      title: '说明'
    }
  },
  {
    path: '/jieshao',
    name: 'jieshao',
    component: jieshao,
    meta: {
      title: '产品介绍'
    }
  },
  {
    path: '/jieshao1',
    name: 'jieshao1',
    component: jieshao1,
    meta: {
      title: '产品介绍'
    }
  }
]

export default routes
