import { render, staticRenderFns } from "./Glossoscopy.vue?vue&type=template&id=ab25a9a8&scoped=true&"
import script from "./Glossoscopy.vue?vue&type=script&lang=js&"
export * from "./Glossoscopy.vue?vue&type=script&lang=js&"
import style0 from "./Glossoscopy.vue?vue&type=style&index=0&id=ab25a9a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab25a9a8",
  null
  
)

export default component.exports