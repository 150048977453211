<template>
  <div class="statement">
    <div>免责声明</div>
    <span>
                欢迎您使用个人健康评估报告（以下简称：健康报告），本人已经阅读健康报告服务条款并同意本声明。
          </span>
    <h2></h2>
    <span>
            健康报告是针对亚健康人群进行科学化数据指标采集设备，并非医疗器械设备。健康报告所有资料及数据报告仅供参考使用，不作为个人健康状况的医疗目的衡量指标，也不能替代医生和其他医务人员的专业建议。
          </span>
    <h2></h2>
    <span>
            在任何情况下，健康报告所提供数据仅为提升用户体验所用，用户对于其自身健康状况的最终评估仅来自于专业医疗机构的检测手段，健康报告对于采集数据不做任何形式的保证，不保证所提供数据满足用户的要求，对采集结果的安全性、正确性、及时性均不做保证。故本产品不对因使用本品提供的数据而导致之损失或损害承担任何直接或间接责任。
          </span>
    <h2></h2>
    <span>
            健康报告是为亚健康人群设计，相关指标依据人体数据采集系统而成，经过科学及实践测算出来，并非特别针对残缺人士及疾病患者，此类人群使用可能会出现个别指标测量不精准。
          </span>
    <h2></h2>
    <span>
            健康报告推送的内容，数据指标、运动调理、健康食谱、健康设备、调养方法等均作为个人健康管理所需的参考，不作为处方，用户可根据自身情况结合专业诊断结果进行。本产品不对通过健康报告服务得到的或在其信息平台上所推介的产品或服务做任何担保。如用户进行采纳健康报告所推送的内容所产生一切后果，本产品不承担任何责任。
            </span>
    <h2></h2>
    <span>
            健康报告是基于假定用户{result}情况下进行的数据采集，采集结果每时每刻都在发生变化，所以建议测量选择固定时间点，最好是空腹平静心态下测量会更有效。用户通过健康报告获取材料或数据由自己承担风险，同时将对使用此类材料或数据而导致的人身或财产损失承担全部责任。
            </span>
    <h2></h2>
    <span>
            因数据报告里面包含个人隐私问题，当用户分享给它人或朋友圈时，即表示该用户自行同意把隐私公开。
            </span>
    <h2></h2>
    <span>
            本产品重视对用户隐私的保护，用户提供的登记资料及本产品保留的有关若干其他个人资料将受到中国有关隐私的法律法规的保护。非经用户亲自许可或根据相关法律的强制性规定，本产品不会主动的将用户的信息泄露给其他任何第三方。
            </span>
    <h2></h2>
    <span>
            本产品有权在必要时修改服务条款，服务条款一旦发生变动，将会在相关页面上公布修改后的服务条款。如果不同意所改动的内容，用户应主动取消此项服务。如果用户继续使用服务，则视为接受服务条款的变动。
            </span>
    <h2></h2>
    <span>
            凡以任何方式登录健康报告，或对用户使用健康报告、网络平台、与本产品相关的任何内容、服务均视为自愿接受健康报告声明的约束。无论在任何原因下（包括但不限于疏忽原因），对用户或任何人通过使用健康报告所导致的损失或损害（包括直接、间接的损失或损害），责任均由使用者自行承担（除非该等损失系由本产品质量不达标所致）。
          </span>
  </div>
</template>

<script>
export default {
  name: 'view_bottom'
}
</script>

<style scoped>
.statement {
  margin: 50px;
}

.statement > div:nth-child(1) {
  font-size: 35px;
  color: #000000;
  font-weight: 650;
  padding-bottom: 30px;
}

.statement > span {
  color: rgba(166, 166, 166, 1);
  line-height: 50px;
}

</style>
