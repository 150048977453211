<template>
  <div class="view">
    <div style="height: 20vh;background: #e9c7a0;display: flex">
      <div
          style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
          @click="$router.back()">
        <img v-if="$route.query && $route.query.showTitle" src="../../assets/images/back.png"
             style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('蛋白质代谢')">
        <div class="view_content_bg_item_title">
          <span>蛋白质代谢</span><span>{{ proteinIndex }}分</span><span>/100</span><span>{{ protein }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="protein" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('能量生产')">
        <div class="view_content_bg_item_title">
          <span>能量生产</span><span>{{ productionIndex }}分</span><span>/100</span><span>{{ production }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="production" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('解毒')">
        <div class="view_content_bg_item_title">
          <span>解毒</span><span>{{ detoxificationIndex }}分</span><span>/100</span><span>{{ detoxification }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="detoxification" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('胆汁分泌')">
        <div class="view_content_bg_item_title">
          <span>胆汁分泌</span><span>{{ secretionIndex }}分</span><span>/100</span><span>{{ secretion }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="secretion" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('肝内脂肪含量')">
        <div class="view_content_bg_item_title">
          <span>肝内脂肪含量</span><span>{{ intrahepaticIndex }}分</span><span>/100</span><span>{{ intrahepatic }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="intrahepatic" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('血清球蛋白')">
        <div class="view_content_bg_item_title">
          <span>血清球蛋白</span><span>{{ globulinIndex }}分</span><span>/100</span><span>{{ globulin }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="globulin" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('总胆红素')">
        <div class="view_content_bg_item_title">
          <span>总胆红素</span><span>{{ bilirubinsIndex }}分</span><span>/100</span><span>{{ bilirubins }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bilirubins" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('碱性磷酸脂酶')">
        <div class="view_content_bg_item_title">
          <span>碱性磷酸脂酶</span><span>{{ phosphataseIndex }}分</span><span>/100</span><span>{{ phosphatase }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="phosphatase" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('血清总胆汁酸')">
        <div class="view_content_bg_item_title">
          <span>血清总胆汁酸</span><span>{{ acidIndex }}分</span><span>/100</span><span>{{ acid }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="acid" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('胆红素')">
        <div class="view_content_bg_item_title">
          <span>胆红素</span><span>{{ bilirubinIndex }}分</span><span>/100</span><span>{{ bilirubin }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bilirubin" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'

export default {
  name: 'ReportC',
  components: { view_bottom },
  data() {
    return {
      protein: '',
      proteinIndex: '',
      production: '',
      productionIndex: '',
      detoxification: '',
      detoxificationIndex: '',
      secretion: '',
      secretionIndex: '',
      intrahepatic: '',
      intrahepaticIndex: '',
      globulin: '',
      globulinIndex: '',
      bilirubins: '',
      bilirubinsIndex: '',
      phosphatase: '',
      phosphataseIndex: '',
      acid: '',
      acidIndex: '',
      bilirubin: '',
      bilirubinIndex: '',
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/getliver?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.protein = result.data.protein
            this.proteinIndex = result.data.proteinIndex
            this.production = result.data.production
            this.productionIndex = result.data.productionIndex
            this.detoxification = result.data.detoxification
            this.detoxificationIndex = result.data.detoxificationIndex
            this.secretion = result.data.secretion
            this.secretionIndex = result.data.secretionIndex
            this.intrahepatic = result.data.intrahepatic
            this.intrahepaticIndex = result.data.intrahepaticIndex
            this.globulin = result.data.globulin
            this.globulinIndex = result.data.globulinIndex
            this.bilirubins = result.data.bilirubins
            this.bilirubinsIndex = result.data.bilirubinsIndex
            this.phosphatase = result.data.phosphatase
            this.phosphataseIndex = result.data.phosphataseIndex
            this.acid = result.data.acid
            this.acidIndex = result.data.acidIndex
            this.bilirubin = result.data.bilirubin
            this.bilirubinIndex = result.data.bilirubinIndex

            this.initBarChart('protein', this.protein)
            this.initBarChart('production', this.production)
            this.initBarChart('detoxification', this.detoxification)
            this.initBarChart('secretion', this.secretion)
            this.initBarChart('intrahepatic', this.intrahepatic)
            this.initBarChart('globulin', this.globulin)
            this.initBarChart('bilirubins', this.bilirubins)
            this.initBarChart('phosphatase', this.phosphatase)
            this.initBarChart('acid', this.acid)
            this.initBarChart('bilirubin', this.bilirubin)

          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },

        },
        yAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    { offset: 0, color: '#dc6666' },                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    { offset: 1, color: '#dc6666' },                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      }
      jiankang.setOption(option)
    },
    openValue(info) {
      //this.$router.push({ path: '/Healthy/Introduce', query: { userId: info, date: new Date().getTime() } })
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: #f14848;
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: #dc6666;
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
