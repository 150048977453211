<template>
  <div class="view">
    <div class="view_title">
      <div v-if="$route.query && $route.query.showTitle"
           style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
           @click="$router.back()">
        <img src="../../assets/images/back.png" style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>

    <div class="view_content_bg">
      <div class="view_content_header">
        <div class="view_content_sub_header">
          <div class="view_content_header_top" style="flex: 5">
            <div class="view_content_header_top_text">您的生理年龄约</div>
            <div class="view_content_header_bottom_text">{{ physiologicalAge }}岁</div>
          </div>
          <div class="view_content_header_center"/>
          <div class="view_content_header_top" style="flex: 5">
            <div class="view_content_header_top_text">您的实际年龄</div>
            <div class="view_content_header_bottom_text">{{ age }}岁</div>
          </div>
        </div>
      </div>

      <div class="view_content_center_text">
        图表趋势
      </div>

      <div>
        <div id="myChart" :style="{ width: '100%',height:'350px' }"></div>
      </div>

      <div class="view_content_bottom">
        <div class="view_content_bottom_border">
          <div class="view_content_bottom_border_text">
            <span>心脑血管</span>
            <span>{{ bloodVessel }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>{{ ageName }}</span>
            <span>{{ male }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>{{ sexName }}</span>
            <span>{{ sex }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>骨骼</span>
            <span>{{ bones }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>免疫</span>
            <span>{{ immune }}</span>
          </div>
        </div>
        <div class="view_content_bottom_centos">
          <div>年轻</div>
          <div>正常</div>
          <div>衰老</div>
        </div>
        <div class="view_content_bottom_border">
          <div class="view_content_bottom_border_text">
            <span>肠胃</span>
            <span>{{ stomach }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>肝胆</span>
            <span>{{ hepatobiliary }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>肾脏</span>
            <span>{{ kidney }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>呼吸</span>
            <span>{{ breathing }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>内分泌</span>
            <span>{{ endocrine }}</span>
          </div>
        </div>
      </div>
    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'
import view from '../../assets/css/view.css'

export default {
  name: 'PhysiologicalAge',
  components: { view_bottom },
  data() {
    return {
      indicator: [],
      value: [],
      physiologicalAge: '',
      age: '',
      bloodVessel: '',
      sexName: '',
      stomach: '',
      hepatobiliary: '',
      kidney: '',
      breathing: '',
      endocrine: '',
      immune: '',
      bones: '',
      male: '',
      ageName: '',
      sex: '',
      count: '',
      count_1: '',
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/getShengli?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            this.physiologicalAge = result.data.physiologicalAge
            this.age = result.data.age
            this.bloodVessel = result.data.bloodVessel
            if (result.data.gender === '1') {
              this.sexName = '男性'
              this.ageName = '前列腺'
            } else {
              this.sexName = '女性'
              this.ageName = '乳腺'
            }
            this.stomach = result.data.stomach
            this.hepatobiliary = result.data.hepatobiliary
            this.kidney = result.data.kidney
            this.breathing = result.data.breathing
            this.endocrine = result.data.endocrine
            this.immune = result.data.immune
            this.bones = result.data.bones
            this.male = result.data.male
            this.sex = result.data.sex

            this.count = parseInt(this.physiologicalAge) * 2
            this.count_1 = (parseInt(this.physiologicalAge) * 2) + 10

            this.indicator = [
              { text: '心脑血管', max: this.count },
              { text: '肠胃', max: this.count },
              { text: '肝胆', max: this.count },
              { text: '肾脏', max: this.count },
              { text: '呼吸', max: this.count },
              { text: '内分泌', max: this.count },
              { text: '免疫', max: this.count },
              { text: '骨骼', max: this.count },
              { text: this.sexName, max: this.count },
              { text: this.ageName, max: this.count_1 },
            ]

            this.value = [this.bloodVessel, this.stomach, this.hepatobiliary, this.kidney, this.breathing,
              this.endocrine, this.immune, this.bones, this.sex, this.male]
            this.initBarChart()
          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      let i = -1
      let option = {
        radar:
            {
              indicator: this.indicator,
              radius: 90,
              splitNumber: 3,
              shape: 'circle',
              axisName: {
                formatter: (a, b) => {
                  i++
                  return `{b|${this.value[i]}}\n{a|${a}}`
                },
                rich: {
                  b: {
                    color: '#dc6666',
                    width: '50px',
                    padding: 5,
                    fontSize: 14,
                  },
                  a: {
                    color: '#333',
                    lineHeight: 25,
                    padding: 2,
                    borderRadius: 4,
                    fontSize: 14,
                  },
                }
              },
              nameGap: 20,
              splitArea: {
                areaStyle: {
                  color: ['#f4a21a', '#2eadaf', '#3178bd'],
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 10
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff',
                  width: 3,
                }
              },
            },
        series:
            {
              type: 'radar',
              emphasis: {
                lineStyle: {
                  width: 100
                }
              },
              symbol: 'none',
              data: [
                {
                  value: this.value,
                  lineStyle: {
                    width: 3,
                    color: '#8ac256'
                  },
                  label: {
                    normal: {
                      show: true,
                      formatter: function (params) {
                        return params.value
                      }
                    }
                  }
                }
              ]
            }
      }
      // 绘制图表
      myChart.setOption(option)
    },
  }
  ,
}
</script>

<style scoped>

.view_title {
  background: #e9c7a0;
  height: 20vh;
}

.view_content_header {
  display: flex;
  justify-content: center;
}

.view_content_sub_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 72vw;
  height: 18vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/report_subtitle_bg.png');
}

.view_content_header_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.view_content_header_top_text {
  font-size: 1.5vh;
  font-weight: 400;
  color: rgba(112, 112, 112, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_header_bottom_text {
  font-size: 1.8vh;
  font-weight: 700;
  color: #dc6666;
  text-align: left;
  vertical-align: top;
}

.view_content_header_center {
  width: 2px;
  height: 10vw;
  background: #CCCCCC;
}

.view_content_center_text {
  font-size: 32px;
  font-weight: 700;
  line-height: 28px;
  color: rgba(56, 56, 56, 1);
  text-align: left;
  vertical-align: top;
  margin-left: 32px;
  margin-top: 48px;
}

.view_content_bottom {
  padding: 48px 32px;
  display: flex;
  justify-content: space-between;
}

.view_content_bottom_border {
  width: 40%;
  padding: 0 36px 24px 32px;
  background: #F7FAFA;
  border-radius: 32px;

}

.view_content_bg {
  background-color: #ffffff;
  border-radius: 24px;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: -10vh;
}

.view_content_header {
  display: flex;
  justify-content: center;
}

.view_content_sub_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 72vw;
  height: 18vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/report_subtitle_bg.png');
}

.view_content_bottom_centos {
  width: 20%;
  margin: auto;
}

.view_content_bottom_border_text {
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
}

.view_content_bottom_border_text > span:nth-child(1) {
  font-size: 28px;
  color: #999999;
}

.view_content_bottom_border_text > span:nth-child(2n) {
  color: #000000;
  font-size: 28px;
  font-weight: 700;
}

.view_content_bottom_centos > div {
  height: 48px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.view_content_bottom_centos > div:nth-child(1) {
  background: #f4a21a;
}

.view_content_bottom_centos > div:nth-child(2) {
  background: #2eadaf;
}

.view_content_bottom_centos > div:nth-child(3) {
  background: #3178bd;
}
</style>
