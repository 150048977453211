<template>
  <div class="view">
    <div style="height: 20vh;background: #e9c7a0;display: flex">
      <div
          style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
          @click="$router.back()">
        <img v-if="$route.query && $route.query.showTitle" src="../../assets/images/back.png"
             style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('血液黏度')">
        <div class="view_content_bg_item_title">
          <span>血液黏度</span><span>{{ bloodIndex }}分</span><span>/100</span><span>{{ blood }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="jiankang" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('胆固醇结晶')">
        <div class="view_content_bg_item_title">
          <span>胆固醇结晶</span><span>{{ crystalIndex }}分</span><span>/100</span><span>{{ crystal }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="qingxu" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('血脂')">
        <div class="view_content_bg_item_title">
          <span>血脂</span><span>{{ bloodFatIndex }}分</span><span>/100</span><span>{{ bloodFat }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="naoli" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('血管阻力')">
        <div class="view_content_bg_item_title">
          <span>血管阻力</span><span>{{ resistanceIndex }}分</span><span>/100</span><span>{{ resistance }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="fanyingli" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('血管弹性')">
        <div class="view_content_bg_item_title">
          <span>血管弹性</span><span>{{ lsElasticityIndex }}分</span><span>/100</span><span>{{ lsElasticity }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="jiyili" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('心肌血液需量')">
        <div class="view_content_bg_item_title">
          <span>心肌血液需量</span><span>{{ demandIndex }}分</span><span>/100</span><span>{{ demand }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="yangbohe" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('心肌血液灌注量')">
        <div class="view_content_bg_item_title">
          <span>心肌血液灌注量</span><span>{{ perfusionIndex }}分</span><span>/100</span><span>{{ perfusion }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="suanjian" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('心机耗氧量')">
        <div class="view_content_bg_item_title">
          <span>心肌耗氧量</span><span>{{ consumptionIndex }}分</span><span>/100</span><span>{{ consumption }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="shuifen" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('每搏心搏出量')">
        <div class="view_content_bg_item_title">
          <span>每搏心搏出量</span><span>{{ perStrokeIndex }}分</span><span>/100</span><span>{{ perStroke }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="meibo" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('左心室喷血阻抗')">
        <div class="view_content_bg_item_title">
          <span>左心室喷血阻抗</span><span>{{ leftImpedanceIndex }}分</span><span>/100</span><span>{{ leftImpedance }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="leftxinshi" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('左心室有效泵力')">
        <div class="view_content_bg_item_title">
          <span>左心室有效泵力</span><span>{{ leftVentricleIndex }}分</span><span>/100</span><span>{{ leftVentricle }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="leftxinbeng" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('冠状动脉弹性')">
        <div class="view_content_bg_item_title">
          <span>冠状动脉弹性</span><span>{{ coronaryElasticityIndex }}分</span><span>/100</span><span>{{
            coronaryElasticity
          }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="guanzhuang" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('冠状动脉灌注压')">
        <div class="view_content_bg_item_title">
          <span>冠状动脉灌注压</span><span>{{ pressureIndex }}分</span><span>/100</span><span>{{ pressure }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="guanzhuangf" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('脑血管弹性')">
        <div class="view_content_bg_item_title">
          <span>脑血管弹性</span><span>{{ elasticityIndex }}分</span><span>/100</span><span>{{ elasticity }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="naoxueguan" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('脑组织供血')">
        <div class="view_content_bg_item_title">
          <span>脑组织供血</span><span>{{ bloodSupplyIndex }}分</span><span>/100</span><span>{{ bloodSupply }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="naozhuzhi" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('脑动脉硬化')">
        <div class="view_content_bg_item_title">
          <span>脑动脉硬化</span><span>{{ cerebralArterioscleIndexrosis }}分</span><span>/100</span><span>{{
            cerebralArteriosclerosis
          }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="naodunmai" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'

export default {
  name: 'ReportA',
  components: { view_bottom },
  data() {
    return {
      blood: '',
      bloodIndex: '',
      crystal: '',
      crystalIndex: '',
      bloodFat: '',
      bloodFatIndex: '',
      resistance: '',
      resistanceIndex: '',
      lsElasticity: '',
      lsElasticityIndex: '',
      demand: '',
      demandIndex: '',
      perfusion: '',
      perfusionIndex: '',
      consumption: '',
      consumptionIndex: '',
      perStroke: '',
      perStrokeIndex: '',
      leftImpedance: '',
      leftImpedanceIndex: '',
      leftVentricle: '',
      leftVentricleIndex: '',
      coronaryElasticity: '',
      coronaryElasticityIndex: '',
      pressure: '',
      pressureIndex: '',
      elasticity: '',
      elasticityIndex: '',
      bloodSupply: '',
      bloodSupplyIndex: '',
      cerebralArteriosclerosis: '',
      cerebralArterioscleIndexrosis: '',
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/getCardiovascular?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.blood = result.data.blood
            this.bloodIndex = result.data.bloodIndex
            this.crystalIndex = result.data.crystalIndex
            this.crystal = result.data.crystal
            this.bloodFat = result.data.bloodFat
            this.bloodFatIndex = result.data.bloodFatIndex
            this.resistance = result.data.resistance
            this.resistanceIndex = result.data.resistanceIndex
            this.lsElasticity = result.data.lsElasticity
            this.lsElasticityIndex = result.data.lsElasticityIndex
            this.demand = result.data.demand
            this.demandIndex = result.data.demandIndex
            this.perfusion = result.data.perfusion
            this.perfusionIndex = result.data.perfusionIndex
            this.consumption = result.data.consumption
            this.consumptionIndex = result.data.consumptionIndex
            this.perStroke = result.data.perStroke
            this.perStrokeIndex = result.data.perStrokeIndex
            this.leftImpedance = result.data.leftImpedance
            this.leftImpedanceIndex = result.data.leftImpedanceIndex
            this.leftVentricle = result.data.leftVentricle
            this.leftVentricleIndex = result.data.leftVentricleIndex
            this.coronaryElasticity = result.data.coronaryElasticity
            this.coronaryElasticityIndex = result.data.coronaryElasticityIndex
            this.pressure = result.data.pressure
            this.pressureIndex = result.data.pressureIndex
            this.elasticity = result.data.elasticity
            this.elasticityIndex = result.data.elasticityIndex
            this.bloodSupply = result.data.bloodSupply
            this.bloodSupplyIndex = result.data.bloodSupplyIndex
            this.cerebralArteriosclerosis = result.data.cerebralArteriosclerosis
            this.cerebralArterioscleIndexrosis = result.data.cerebralArterioscleIndexrosis

            this.initBarChart('jiankang', this.blood)
            this.initBarChart('qingxu', this.crystal)
            this.initBarChart('naoli', this.bloodFat)
            this.initBarChart('fanyingli', this.resistance)
            this.initBarChart('jiyili', this.lsElasticity)
            this.initBarChart('yangbohe', this.demand)
            this.initBarChart('suanjian', this.perfusion)
            this.initBarChart('shuifen', this.consumption)
            this.initBarChart('meibo', this.perStroke)
            this.initBarChart('leftxinshi', this.leftImpedance)
            this.initBarChart('leftxinbeng', this.leftVentricle)
            this.initBarChart('guanzhuang', this.coronaryElasticity)
            this.initBarChart('guanzhuangf', this.pressure)
            this.initBarChart('naoxueguan', this.elasticity)
            this.initBarChart('naozhuzhi', this.bloodSupply)
            this.initBarChart('naodunmai', this.cerebralArteriosclerosis)
          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },

        },
        yAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    { offset: 0, color: '#dc6666' },                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    { offset: 1, color: '#dc6666' },                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      }
      jiankang.setOption(option)
    },
    openValue(info) {
      //this.$router.push({ path: '/Healthy/Introduce', query: { userId: info, date: new Date().getTime() } })
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: #f14848;
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: #dc6666;
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
