<template>
  <div>
    <div style="height: 20vh;background: #e9c7a0;display: flex">
      <div v-if="$route.query && $route.query.showTitle"
           style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
           @click="$router.back()">
        <img src="../../assets/images/back.png" style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div class="healthy">
      <div>
        <!-- 详细健康评估 -->
        <div class="comments">
          <div class="comments_title">
            <span></span>
            <span>详细健康状况评估</span>
          </div>
          <div class="commentsItem">
            <div class="assessment" @click="openReportA()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn1.png"
              />
              <div class="assessmentText">{{ bloodVessel }}</div>
              <div class="col9">心脑血管</div>
            </div>
            <div class="assessment" @click="openReportB()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn2.png"
              />
              <div class="assessmentText">{{ stomach }}</div>
              <div class="col9">肠胃</div>
            </div>
            <div class="assessment" @click="openReportC()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn3.png"
              />
              <div class="assessmentText">{{ liver }}</div>
              <div class="col9">肝胆</div>
            </div>
            <div class="assessment" @click="openReportD()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn4.png"
              />
              <div class="assessmentText">{{ kidney }}</div>
              <div class="col9">肾脏</div>
            </div>
            <div class="assessment" @click="openReportE()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn5.png"
              />
              <div class="assessmentText">{{ breathing }}</div>
              <div class="col9">&#8195;呼吸&#8195;</div>
            </div>
            <div class="assessment" @click="openReportF()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn6.png"
              />
              <div class="assessmentText">{{ endocrine }}</div>
              <div class="col9">内分泌</div>
            </div>
          </div>
          <div class="commentsItem">
            <div class="assessment" @click="openReportH()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn7.png"
              />
              <div class="assessmentText">{{ bones }}</div>
              <div class="col9">骨骼</div>
            </div>
            <div class="assessment" @click="openReport()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn8.png"
              />
              <div class="assessmentText">{{ gynaecology }}</div>
              <div class="col9">妇科男科</div>
            </div>
            <div class="assessment" @click="openReportI()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn9.png"
              />
              <div class="assessmentText">{{ skin }}</div>
              <div class="col9">皮肤</div>
            </div>
            <div class="assessment" @click="openReportJ()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn10.png"
              />
              <div class="assessmentText">{{ eyes }}</div>
              <div class="col9">眼部</div>
            </div>
            <div class="assessment" @click="openReportK()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn11.png"
              />
              <div class="assessmentText">{{ vitamin }}</div>
              <div class="col9">维生素</div>
            </div>
            <div class="assessment" @click="openReportG()">
              <img
                  alt=""
                  src="../../assets/images/health_detail_btn12.png"
              />
              <div class="assessmentText">{{ immune }}</div>
              <div class="col9">免疫</div>
            </div>
          </div>
        </div>
        <!-- //免责声明 -->
        <div class="statement">
          <div>免责声明</div>
          <span>
                欢迎您使用个人健康评估报告（以下简称：健康报告），本人已经阅读健康报告服务条款并同意本声明。
          </span>
          <h2></h2>
          <span>
            健康报告是针对亚健康人群进行科学化数据指标采集设备，并非医疗器械设备。健康报告所有资料及数据报告仅供参考使用，不作为个人健康状况的医疗目的衡量指标，也不能替代医生和其他医务人员的专业建议。
          </span>
          <h2></h2>
          <span>
            在任何情况下，健康报告所提供数据仅为提升用户体验所用，用户对于其自身健康状况的最终评估仅来自于专业医疗机构的检测手段，健康报告对于采集数据不做任何形式的保证，不保证所提供数据满足用户的要求，对采集结果的安全性、正确性、及时性均不做保证。故本产品不对因使用本品提供的数据而导致之损失或损害承担任何直接或间接责任。
          </span>
          <h2></h2>
          <span>
            健康报告是为亚健康人群设计，相关指标依据人体数据采集系统而成，经过科学及实践测算出来，并非特别针对残缺人士及疾病患者，此类人群使用可能会出现个别指标测量不精准。
          </span>
          <h2></h2>
          <span>
            健康报告推送的内容，数据指标、运动调理、健康食谱、健康设备、调养方法等均作为个人健康管理所需的参考，不作为处方，用户可根据自身情况结合专业诊断结果进行。本产品不对通过健康报告服务得到的或在其信息平台上所推介的产品或服务做任何担保。如用户进行采纳健康报告所推送的内容所产生一切后果，本产品不承担任何责任。
            </span>
          <h2></h2>
          <span>
            健康报告是基于假定用户{result}情况下进行的数据采集，采集结果每时每刻都在发生变化，所以建议测量选择固定时间点，最好是空腹平静心态下测量会更有效。用户通过健康报告获取材料或数据由自己承担风险，同时将对使用此类材料或数据而导致的人身或财产损失承担全部责任。
            </span>
          <h2></h2>
          <span>
            因数据报告里面包含个人隐私问题，当用户分享给它人或朋友圈时，即表示该用户自行同意把隐私公开。
            </span>
          <h2></h2>
          <span>
            本产品重视对用户隐私的保护，用户提供的登记资料及本产品保留的有关若干其他个人资料将受到中国有关隐私的法律法规的保护。非经用户亲自许可或根据相关法律的强制性规定，本产品不会主动的将用户的信息泄露给其他任何第三方。
            </span>
          <h2></h2>
          <span>
            本产品有权在必要时修改服务条款，服务条款一旦发生变动，将会在相关页面上公布修改后的服务条款。如果不同意所改动的内容，用户应主动取消此项服务。如果用户继续使用服务，则视为接受服务条款的变动。
            </span>
          <h2></h2>
          <span>
            凡以任何方式登录健康报告，或对用户使用健康报告、网络平台、与本产品相关的任何内容、服务均视为自愿接受健康报告声明的约束。无论在任何原因下（包括但不限于疏忽原因），对用户或任何人通过使用健康报告所导致的损失或损害（包括直接、间接的损失或损害），责任均由使用者自行承担（除非该等损失系由本产品质量不达标所致）。
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'

export default {
  name: 'Details',
  components: {},
  data() {
    return {
      model: {
        healthyType: 0
      },
      top_image: '',
      username: '',
      age: '',
      height: '',
      weight: '',
      sex: '',
      physiologicalAge: '',
      bloodVessel: '',
      stomach: '',
      liver: '',
      kidney: '',
      breathing: '',
      endocrine: '',
      immune: '',
      bones: '',
      gynaecology: '',
      skin: '',
      eyes: '',
      vitamin: '',
      getqyinfo: '',
      grade: '',
      timerout: '',
      healthyTypes: [
        { text: '平和体质' }, { text: '气滞体质' }, { text: '血瘀体质' },
        { text: '阳虚体质' }, { text: '阴虚体质' }, { text: '气虚体质' },
        { text: '血虚体质' }, { text: '痰湿体质' }, { text: '特凛体质' }]
    }
  },
  created() {
    // document.title = '健康报告'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/details?id=${id}`
      axios.post(url)
          .then((result) => {
            let user = result.data.user
            let userState = result.data.userState
            console.log(user)
            this.username = user.name
            this.age = userState.age
            this.outId = userState.outId
            this.birthday = userState.birthday
            this.weight = userState.weight
            this.height = userState.height
            let physiological = userState.physiologicalAge
            this.physiologicalAge = physiological.toString()
            this.bloodVessel = userState.bloodVessel
            this.stomach = userState.stomach
            this.liver = userState.liver
            this.kidney = userState.kidney
            this.breathing = userState.breathing
            this.endocrine = userState.endocrine
            this.immune = userState.immune
            this.bones = userState.bones
            this.gynaecology = userState.gynaecology
            this.skin = userState.skin
            this.eyes = userState.eyes
            this.vitamin = userState.vitamin
            this.timerout = userState.timeout
            this.constitution = userState.constitution || 0
            this.top_image = 'api/sys/common/static/' + userState.headPortrait
            if (userState.sex === 1) {
              this.sex = '男'
            } else {
              this.sex = '女'
            }
            let grades = parseInt(this.bloodVessel) + parseInt(this.stomach) + parseInt(this.liver) +
                parseInt(this.kidney) + parseInt(this.breathing) + parseInt(this.endocrine) + parseInt(this.immune) +
                parseInt(this.bones) + parseInt(this.gynaecology) + parseInt(this.skin) + parseInt(this.eyes) + parseInt(this.vitamin)

            let grades_count = grades / 12
            if (grades_count > 90) {
              this.grade = '5'
            } else if (grades_count > 85) {
              this.grade = '4'
            } else {
              this.grade = '3'
            }

            let details = result.data.userDetails

            this.bodyfluidNames = details.bodyfluidnames
            this.bodyfluidDescribe = details.bodyfluiddescribe
            this.colorOfMossNames = details.colorofmossnames
            this.colorOfMossDescribe = details.colorofmossdescribe
            this.colorOfTongueNames = details.coloroftonguenames
            this.colorOfTongueDescribe = details.coloroftonguedescribe
            this.colorOfTongueDescribe = details.coloroftonguedescribe
            this.mossNames = details.mossnames
            this.mossDescribe = details.mossdescribe
            this.shapeOfTongueNames = details.shapeoftonguenames
            this.shapeOfTongueDescribe = details.shapeoftonguedescribe
            this.shapeOfTongueDescribe = details.shapeoftonguedescribe
            this.veinNames = details.veinnames
            this.veinDescribe = details.veindescribe
            this.constitutionNames = details.constitutionnames
            this.constitutionDescribe = details.constitutiondescribe
            this.singleConfigName = details.singleconfigname
            this.ossOriImgUrl = details.ossoriimgurl
            this.ossSplitImgUrl = details.osssplitimgurl
            this.ossSplitBackImgUrl = details.osssplitbackimgurl
            let json = JSON.parse(details.treatplanjson)
            console.log(json)
            this.diagnostic_results = json[0].diagnostic_results
            this.sports_health_care = json[0].sports_health_care
            this.diet_rehabilitation = json[0].diet_rehabilitation
            this.predisposition = json[0].predisposition
            this.massage_health_care = json[0].massage_health_care
            this.main_performance = json[0].main_performance
            this.occur_reason = json[0].occur_reason
            this.drug_health_care = json[0].drug_health_care
            this.recuperates = json[0].recuperates
          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    openGl() {
      this.$router.push({ path: '/Healthy/Glossoscopy', query: { id: this.id } })
      // if (this.timerout === '90') {
      // } else {
      //   Toast('未做舌诊报告')
      // }
    },
    openPulse() {
      if (this.timerout === '90') {
        this.$router.push({ path: '/Healthy/Pulse', query: { id: this.id } })
      } else {
        Toast('未做脉诊报告')
      }
    },
    openSound() {
      if (this.timerout === '90') {
        this.$router.push({ path: '/Healthy/Sound', query: { id: this.id } })
      } else {
        Toast('未做声强报告')
      }
    },
    openPhy() {
      this.$router.push({ path: '/Healthy/PhysiologicalAge', query: { id: this.id } })
    },
    openHealthy() {

      this.$router.push({ path: '/Healthy/Healthymind', query: { id: this.id } })
    },
    openHealthServices() {
      if (this.timerout === '90') {
        this.$router.push({ path: '/Healthy/HealthServices', query: { id: this.id } })
      } else {
        Toast('未做舌诊报告')
      }
    },
    openReportA() {
      this.$router.push({ path: '/Healthy/ReportA', query: { id: this.id } })
    },
    openReportB() {
      this.$router.push({ path: '/Healthy/ReportB', query: { id: this.id } })
    },
    openReportC() {
      this.$router.push({ path: '/Healthy/ReportC', query: { id: this.id } })
    },
    openReportD() {
      this.$router.push({ path: '/Healthy/ReportD', query: { id: this.id } })
    },
    openReportE() {
      this.$router.push({ path: '/Healthy/ReportE', query: { id: this.id } })
    },
    openReportF() {
      this.$router.push({ path: '/Healthy/ReportF', query: { id: this.id } })
    },
    openReportG() {
      this.$router.push({ path: '/Healthy/ReportG', query: { id: this.id } })
    },
    openReportH() {
      this.$router.push({ path: '/Healthy/ReportH', query: { id: this.id } })
    },
    openReportI() {
      this.$router.push({ path: '/Healthy/ReportI', query: { id: this.id } })
    },
    openReportJ() {
      this.$router.push({ path: '/Healthy/ReportJ', query: { id: this.id } })
    },
    openReportK() {
      this.$router.push({ path: '/Healthy/ReportK', query: { id: this.id } })
    },
    openReport() {
      if (this.sex === '男') {
        this.$router.push({ path: '/Healthy/ReportNan', query: { id: this.id } })
      } else {
        this.$router.push({ path: '/Healthy/ReportNv', query: { id: this.id } })
      }
    }
  }
}
</script>

<style scoped>
.statement {
  padding: 50px 30px 0 30px;
}

.statement > div:nth-child(1) {
  font-size: 35px;
  font-weight: 650;
  padding-bottom: 30px;
}

.statement > span {
  line-height: 50px;
}

.col9 {
  color: #999999 !important;
}

.assessmentText {
  color: rgba(215, 100, 35, 1) !important;
  font-weight: 700 !important;
  font-size: 26px;
  padding: 20px 0;
}

.assessment > img {
  width: 75px;
  height: 75px;
  display: block;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem_list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem_list > img {
  width: 88px;
  height: 88px;
  display: block;
}

.commentsItem_list > div {
  margin-top: 20px;
  font-size: 24px;
  color: #000000;
  font-weight: 650;
}

.commentsItem {
  display: flex;
  justify-content: space-between;
}

/* .commentsItem{width: 25%;} */
.comments_title {
  display: flex;
  align-items: center;
}

.comments_title > span:nth-child(1) {
  width: 15px;
  height: 15px;
  background: #dc6666;
  border-radius: 50%;
  display: block;
  margin-right: 15px;
}

.comments_title > span:nth-child(2) {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.pl30 {
  padding-left: 50px;
}

.pt30 {
  padding-top: 50px;
}

.header_title {
  background-color: #ffffff;
  width: 150px;
  height: 150px;
}

.healthy {
  /* background: url(https://img.js.design/assets/img/6330750235a21bf1cb3e9f31.png#84e217802a762075630feb094c1b1025); */
  width: 100%;
  /* height: 100vh; */
  /* background-size: 100% 100% !important; */
}

.header {
  background-color: #ffffff;
  border-radius: 25px 25px 0 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header > div:nth-child(2) {
  font-size: 32px;
  font-weight: 700;
  color: #000000;
  margin-top: 20px;
}

.header_title > img {
  width: 128px;
  height: 128px;
  margin-top: 11px;
  border-radius: 50%;
}

.details_list {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px 45px 45px 45px;
}

.details {
  display: flex;
  /* justify-content: space-between; */
}

.details_item {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.details_item > span:nth-child(1) {
  font-size: 28px;
  color: #000000;
}

.details_item > span:nth-child(2n) {
  color: #999999;
  font-size: 28px;
  margin-left: 5px;
  font-weight: 700;
}

.comments {
  margin-top: -10vh;
  background-color: #ffffff;
  box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 45px 30px;
}
</style>
