<template>
  <div>
    <div class="view_title">
      <div v-if="$route.query && $route.query.showTitle"
           style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
           @click="$router.back()">
        <img src="../../assets/images/back.png" style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div style="display: flex;justify-content: center">
      <div class="sound_bg">
        <div style="font-size: 2vh;font-weight: 600;color: #dc6666;margin-top: 10vh">{{ sound }}分</div>
      </div>
    </div>
    <div style="margin: 30px;font-size: 1.5vh">
      <div style="display: flex;justify-content: space-around">
        <div style="flex: 1;display: flex;justify-content: flex-end;align-items: center">0-50分</div>
        <div style="flex: 3;display: flex;justify-content: center;align-items: center">
          <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
            ------------------------------------------------------------------------
          </div>
        </div>
        <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">气息低且弱</div>
      </div>
      <div style="display: flex;justify-content: space-around">
        <div style="flex: 1;display: flex;justify-content: flex-end;align-items: center">50-80分</div>
        <div style="flex: 3;display: flex;justify-content: center;align-items: center">
          <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
            ------------------------------------------------------------------------
          </div>
        </div>
        <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">气息低且弱</div>
      </div>
      <div style="display: flex;justify-content: space-around">
        <div style="flex: 1;display: flex;justify-content: flex-end;align-items: center">80-100分</div>
        <div style="flex: 3;display: flex;justify-content: center;align-items: center">
          <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
            ------------------------------------------------------------------------
          </div>
        </div>
        <div style="flex: 1;display: flex;justify-content: flex-start;align-items: center;">气息高且强</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'

export default {
  name: 'Details',
  components: {},
  data() {
    return {
      sound: ''
    }
  },
  created() {
    // document.title = '健康报告'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/details?id=${id}`
      axios.post(url)
          .then((result) => {
            console.log(result)
            let userState = result.data.userState
            this.sound = userState.soundScore
          }).catch(error => {
        //this.$router.replace("error")
      })
    },
  }
}
</script>

<style scoped>
.view_title {
  background: #e9c7a0;
  height: 10vh;
}

.statement {
  padding: 50px 30px 0 30px;
}

.sound_bg {
  margin-top: 30px;
  background-image: url("../../assets/images/sound.png");
  background-position: center;
  background-size: contain;
  width: 100vw;
  height: 30vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.statement > div:nth-child(1) {
  font-size: 35px;
  font-weight: 650;
  padding-bottom: 30px;
}

.statement > span {
  line-height: 50px;
}

.col9 {
  color: #999999 !important;
}

.assessmentText {
  color: rgba(215, 100, 35, 1) !important;
  font-weight: 700 !important;
  font-size: 26px;
  padding: 20px 0;
}

.assessment > img {
  width: 75px;
  height: 75px;
  display: block;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem_list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem_list > img {
  width: 88px;
  height: 88px;
  display: block;
}

.commentsItem_list > div {
  margin-top: 20px;
  font-size: 24px;
  color: #000000;
  font-weight: 650;
}

.commentsItem {
  display: flex;
  justify-content: space-between;
}

/* .commentsItem{width: 25%;} */
.comments_title {
  display: flex;
  align-items: center;
}

.comments_title > span:nth-child(1) {
  width: 15px;
  height: 15px;
  background: #dc6666;
  border-radius: 50%;
  display: block;
  margin-right: 15px;
}

.comments_title > span:nth-child(2) {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.pl30 {
  padding-left: 50px;
}

.pt30 {
  padding-top: 50px;
}

.header_title {
  background-color: #ffffff;
  width: 150px;
  height: 150px;
}

.healthy {
  /* background: url(https://img.js.design/assets/img/6330750235a21bf1cb3e9f31.png#84e217802a762075630feb094c1b1025); */
  width: 100%;
  /* height: 100vh; */
  /* background-size: 100% 100% !important; */
}

.header {
  background-color: #ffffff;
  border-radius: 25px 25px 0 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header > div:nth-child(2) {
  font-size: 32px;
  font-weight: 700;
  color: #000000;
  margin-top: 20px;
}

.header_title > img {
  width: 128px;
  height: 128px;
  margin-top: 11px;
  border-radius: 50%;
}

.details_list {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px 45px 45px 45px;
}

.details {
  display: flex;
  /* justify-content: space-between; */
}

.details_item {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.details_item > span:nth-child(1) {
  font-size: 28px;
  color: #000000;
}

.details_item > span:nth-child(2n) {
  color: #999999;
  font-size: 28px;
  margin-left: 5px;
  font-weight: 700;
}

.comments {
  margin-top: -150px;
  background-color: #ffffff;
  box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 45px 30px;
}
</style>
