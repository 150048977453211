<template>
  <div id="app">
    <keep-alive>
      <router-view v-wechat-title="$route.meta.title"/>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      searchText: '',
      RouteState: true,
    }
  },
  methods: {
    reload() {
      this.RouteState = false
      this.$nextTick(() => {
        this.RouteState = true
      })
      this.$router.push({
        name: 'doclist',
        query: {
          keywords: this.searchText
        }
      })
    },
  }
}
</script>

<style>

</style>
