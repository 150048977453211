<template>
  <div class="view">
    <div class="view_title">
      <div v-if="$route.query && $route.query.showTitle"
           style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
           @click="$router.back()">
        <img src="../../assets/images/back.png" style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>

    <div class="view_content_bg" style="padding-bottom: 30px">
      <div class="view_content_header">
        <div class="view_content_sub_header">
          <span style="color: #dc6666;font-size: 2vh;font-weight: 650;">测量结果</span>
        </div>
      </div>

      <div class="view_content_center_text">
        脉率{{ rate }}，脉象诊断如下，点击脉象具体了解
      </div>

      <div
          @click="openValue('紧脉')"
          style="display: flex;align-items: center;margin: 32px;font-size: 1.5vh;font-weight: 500;line-height: 28px;color: rgba(56, 56, 56, 1);cursor: pointer">
        <div class="circle"></div>
        <div class="tag" style="margin: 0 10px">紧脉</div>
        <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
          ------------------------------------------------------------------------
        </div>
        <div style="margin-left: 10px">{{ percentages[0] }}%</div>
      </div>
      <div
          @click="openValue('实脉')"
          style="display: flex;align-items: center;margin: 32px;font-size: 1.5vh;font-weight: 500;line-height: 28px;color: rgba(56, 56, 56, 1);cursor: pointer;">
        <div class="circle"></div>
        <div class="tag" style="margin: 0 10px">实脉</div>
        <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
          ------------------------------------------------------------------------
        </div>
        <div style="margin-left: 10px">{{ percentages[1] }}%</div>
      </div>
      <div
          @click="openValue('弦脉')"
          style="display: flex;align-items: center;margin: 32px;font-size: 1.5vh;font-weight: 500;line-height: 28px;color: rgba(56, 56, 56, 1);cursor: pointer;">
        <div class="circle"></div>
        <div class="tag" style="margin: 0 10px">弦脉</div>
        <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
          ------------------------------------------------------------------------
        </div>
        <div style="margin-left: 10px">{{ percentages[2] }}%</div>
      </div>
      <div
          @click="openValue('洪脉')"
          style="display: flex;align-items: center;margin: 32px;font-size: 1.5vh;font-weight: 500;line-height: 28px;color: rgba(56, 56, 56, 1);cursor: pointer;">
        <div class="circle"></div>
        <div class="tag" style="margin: 0 10px">洪脉</div>
        <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
          ------------------------------------------------------------------------
        </div>
        <div style="margin-left: 10px">{{ percentages[3] }}%</div>
      </div>
      <div
          @click="openValue('滑脉')"
          style="display: flex;align-items: center;margin: 32px;font-size: 1.5vh;font-weight: 500;line-height: 28px;color: rgba(56, 56, 56, 1);cursor: pointer;">
        <div class="circle"></div>
        <div class="tag" style="margin: 0 10px">滑脉</div>
        <div style="overflow: hidden;width: 40vw;display: block;text-overflow: clip;white-space: nowrap;">
          ------------------------------------------------------------------------
        </div>
        <div style="margin-left: 10px">{{ percentages[4] }}%</div>
      </div>

      <div
          style="margin-left: 32px;margin-top: 2vh;font-size: 2vh;font-weight: 700;line-height: 28px;color: rgba(56, 56, 56, 1);">
        温馨提示
      </div>
      <div style="color: rgba(166, 166, 166, 1); line-height: 30px;margin:1vh 2vw;font-size: 1.5vh;">
        脉象相似度以9%以上作为可参考结果。有些脉象波形与证侯相似(如弦，滑，实等)，请用户多测几次，以次数多的为参考结果，参考结果不能用作临床诊断，详情点击查看我司免责声明。脉象、证侯解释与养生建议等说明可用手机截图在相册里放大观看。
      </div>

    </div>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'
import view from '../../assets/css/view.css'

export default {
  name: 'PhysiologicalAge',
  data() {
    return {
      rate: '',
      percentages: [94.78, 88.58, 72.28, 60.14, 44.90]
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/details?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            this.rate = result.data.userState.rate
            this.percentages = result.data.userState.pulse.split(',')
          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      let i = -1
      let option = {
        radar:
            {
              indicator: this.indicator,
              radius: 90,
              splitNumber: 3,
              shape: 'circle',
              axisName: {
                formatter: (a, b) => {
                  i++
                  return `{b|${this.value[i]}}\n{a|${a}}`
                },
                rich: {
                  b: {
                    color: '#dc6666',
                    width: '50px',
                    padding: 5,
                    fontSize: 14,
                  },
                  a: {
                    color: '#333',
                    lineHeight: 25,
                    padding: 2,
                    borderRadius: 4,
                    fontSize: 14,
                  },
                }
              },
              nameGap: 20,
              splitArea: {
                areaStyle: {
                  color: ['#f4a21a', '#2eadaf', '#3178bd'],
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 10
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff',
                  width: 3,
                }
              },
            },
        series:
            {
              type: 'radar',
              emphasis: {
                lineStyle: {
                  width: 100
                }
              },
              symbol: 'none',
              data: [
                {
                  value: this.value,
                  lineStyle: {
                    width: 3,
                    color: '#8ac256'
                  },
                  label: {
                    normal: {
                      show: true,
                      formatter: function (params) {
                        return params.value
                      }
                    }
                  }
                }
              ]
            }
      }
      // 绘制图表
      myChart.setOption(option)
    },
    openValue(info) {
      this.$router.push({ path: '/Healthy/Introduce', query: { id: info, showTitle: this.$route.query.showTitle } })
    }
  }
}
</script>

<style scoped>
.view_title {
  background: #e9c7a0;
  height: 20vh;
}

.view_content_bg {
  background-color: #ffffff;
  border-radius: 24px;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: -10vh;
}

.view_content_header {
  display: flex;
  justify-content: center;
}

.view_content_sub_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 72vw;
  height: 18vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/report_subtitle_bg.png');
}

.view_content_header_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.view_content_header_top_text {
  font-size: 28px;
  font-weight: 400;
  color: rgba(112, 112, 112, 1);
  text-align: left;
  vertical-align: top;
}

.circle {
  width: 2vh;
  height: 2vh;
  background: #dc6666;
  border-radius: 50%;
  display: block;
  margin-right: 15px;
}

.tag {
  background-color: #e9c7a0;
  border-radius: 5px;
  color: white;
  font-size: 1.5vh;
  padding: 0.5vh 2vw;
}

.view_content_header_bottom_text {
  font-size: 30px;
  font-weight: 700;
  color: #dc6666;
  text-align: left;
  vertical-align: top;
  margin-top: 2px;
}

.view_content_header_center {
  width: 2px;
  height: 104px;
  background: #CCCCCC;
}

.view_content_center_text {
  font-size: 1.5vh;
  font-weight: 700;
  line-height: 28px;
  color: rgba(56, 56, 56, 1);
  text-align: left;
  vertical-align: top;
  margin-left: 32px;
  margin-top: 48px;
}

.view_content_bottom {
  padding: 48px 32px;
  display: flex;
  justify-content: space-between;
}

.view_content_bottom_border {
  width: 40%;
  padding: 0 36px 24px 32px;
  background: #F7FAFA;
  border-radius: 32px;

}

.view_content_bottom_centos {
  width: 20%;
  margin: auto;
}

.view_content_bottom_border_text {
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
}

.view_content_bottom_border_text > span:nth-child(1) {
  font-size: 28px;
  color: #999999;
}

.view_content_bottom_border_text > span:nth-child(2n) {
  color: #000000;
  font-size: 28px;
  font-weight: 700;
}

.view_content_bottom_centos > div {
  height: 48px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.view_content_bottom_centos > div:nth-child(1) {
  background: #f4a21a;
}

.view_content_bottom_centos > div:nth-child(2) {
  background: #2eadaf;
}

.view_content_bottom_centos > div:nth-child(3) {
  background: #3178bd;
}
</style>
