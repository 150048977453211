const state = {
  active: 'index', //当前tab名称
}

const mutations = {
  changeTabActive(state, active) {
    state.active = active
  }
}

const actions = {}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters
}
