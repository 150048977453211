<template>
  <div class="view">
    <div style="height: 20vh;background: #e9c7a0;display: flex">
      <div
          style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
          @click="$router.back()">
        <img v-if="$route.query && $route.query.showTitle" src="../../assets/images/back.png"
             style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('甲状腺')">
        <div class="view_content_bg_item_title">
          <span>甲状腺</span><span>{{ endocrine1Index }}分</span><span>/100</span><span>{{ endocrine1 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine1" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('甲状旁腺')">
        <div class="view_content_bg_item_title">
          <span>甲状旁腺</span><span>{{ endocrine2Index }}分</span><span>/100</span><span>{{ endocrine2 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine2" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('肾上腺')">
        <div class="view_content_bg_item_title">
          <span>肾上腺</span><span>{{ endocrine3Index }}分</span><span>/100</span><span>{{ endocrine3 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine3" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('脑垂体')">
        <div class="view_content_bg_item_title">
          <span>脑垂体</span><span>{{ endocrine4Index }}分</span><span>/100</span><span>{{ endocrine4 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine4" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('松果体')">
        <div class="view_content_bg_item_title">
          <span>松果体</span><span>{{ endocrine5Index }}分</span><span>/100</span><span>{{ endocrine5 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine5" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('胸腺分泌')">
        <div class="view_content_bg_item_title">
          <span>胸腺分泌</span><span>{{ endocrine6Index }}分</span><span>/100</span><span>{{ endocrine6 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine6" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('性腺分泌')">
        <div class="view_content_bg_item_title">
          <span>性腺分泌</span><span>{{ endocrine7Index }}分</span><span>/100</span><span>{{ endocrine7 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine7" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('胰岛素')">
        <div class="view_content_bg_item_title">
          <span>胰岛素</span><span>{{ endocrin8Index }}分</span><span>/100</span><span>{{ endocrine8 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine8" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('胰高血糖素')">
        <div class="view_content_bg_item_title">
          <span>胰高血糖素</span><span>{{ endocrin9Index }}分</span><span>/100</span><span>{{ endocrine9 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine9" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('糖耐量')">
        <div class="view_content_bg_item_title">
          <span>糖耐量</span><span>{{ endocrin10Index }}分</span><span>/100</span><span>{{ endocrine10 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine10" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('脂质代谢')">
        <div class="view_content_bg_item_title">
          <span>脂质代谢</span><span>{{ endocrin11Index }}分</span><span>/100</span><span>{{ endocrine11 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine11" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('脂质代谢')">
        <div class="view_content_bg_item_title">
          <span>褐色脂肪组织</span><span>{{ endocrin12Index }}分</span><span>/100</span><span>{{ endocrine12 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="endocrine12" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'

export default {
  name: 'ReportF',
  components: { view_bottom },
  data() {
    return {
      endocrine1: '',
      endocrine2: '',
      endocrine3: '',
      endocrine4: '',
      endocrine5: '',
      endocrine6: '',
      endocrine7: '',
      endocrine8: '',
      endocrine9: '',
      endocrine10: '',
      endocrine11: '',
      endocrine12: '',
      endocrine1Index: '',
      endocrine2Index: '',
      endocrine3Index: '',
      endocrine4Index: '',
      endocrine5Index: '',
      endocrine6Index: '',
      endocrine7Index: '',
      endocrin8Index: '',
      endocrin9Index: '',
      endocrin10Index: '',
      endocrin11Index: '',
      endocrin12Index: '',
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/getendocrine?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.endocrine1 = result.data.endocrine1
            this.endocrine2 = result.data.endocrine2
            this.endocrine3 = result.data.endocrine3
            this.endocrine4 = result.data.endocrine4
            this.endocrine5 = result.data.endocrine5
            this.endocrine6 = result.data.endocrine6
            this.endocrine7 = result.data.endocrine7
            this.endocrine8 = result.data.endocrine8
            this.endocrine9 = result.data.endocrine9
            this.endocrine10 = result.data.endocrine10
            this.endocrine11 = result.data.endocrine11
            this.endocrine12 = result.data.endocrine12
            this.endocrine1Index = result.data.endocrine1Index
            this.endocrine2Index = result.data.endocrine2Index
            this.endocrine3Index = result.data.endocrine3Index
            this.endocrine4Index = result.data.endocrine4Index
            this.endocrine5Index = result.data.endocrine5Index
            this.endocrine6Index = result.data.endocrine6Index
            this.endocrine7Index = result.data.endocrine7Index
            this.endocrin8Index = result.data.endocrine8Index
            this.endocrin9Index = result.data.endocrine9Index
            this.endocrin10Index = result.data.endocrine10Index
            this.endocrin11Index = result.data.endocrine11Index
            this.endocrin12Index = result.data.endocrine12Index

            this.initBarChart('endocrine1', this.endocrine1)
            this.initBarChart('endocrine2', this.endocrine2)
            this.initBarChart('endocrine3', this.endocrine3)
            this.initBarChart('endocrine4', this.endocrine4)
            this.initBarChart('endocrine5', this.endocrine5)
            this.initBarChart('endocrine6', this.endocrine6)
            this.initBarChart('endocrine7', this.endocrine7)
            this.initBarChart('endocrine8', this.endocrine8)
            this.initBarChart('endocrine9', this.endocrine9)
            this.initBarChart('endocrine10', this.endocrine10)
            this.initBarChart('endocrine11', this.endocrine11)
            this.initBarChart('endocrine12', this.endocrine12)

          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },

        },
        yAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    { offset: 0, color: '#dc6666' },                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    { offset: 1, color: '#dc6666' },                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      }
      jiankang.setOption(option)
    },
    openValue(info) {
      //this.$router.push({ path: '/Healthy/Introduce', query: { userId: info, date: new Date().getTime() } })
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: #f14848;
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: #dc6666;
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
