<template>
  <div class="view">
    <div style="height: 20vh;background: #e9c7a0;display: flex">
      <div
          style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
          @click="$router.back()">
        <img v-if="$route.query && $route.query.showTitle" src="../../assets/images/back.png"
             style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            $route.meta && $route.meta.title || ''
          }}</span>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('胃酶分泌')">
        <div class="view_content_bg_item_title">
          <span>胃酶分泌</span><span>{{ enzymeIndex }}分</span><span>/100</span><span>{{ enzyme }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="changmei" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('胃蠕动')">
        <div class="view_content_bg_item_title">
          <span>胃蠕动</span><span>{{ peristalsisIndex }}分</span><span>/100</span><span>{{ peristalsis }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="weirudong" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('胃吸收')">
        <div class="view_content_bg_item_title">
          <span>胃吸收</span><span>{{ absorbIndex }}分</span><span>/100</span><span>{{ absorb }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="weixishou" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('小肠蠕动')">
        <div class="view_content_bg_item_title">
          <span>小肠蠕动</span><span>{{ smallPeristalsisIndex }}分</span><span>/100</span><span>{{ smallPeristalsis }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="xiaochangrud" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('小肠吸收')">
        <div class="view_content_bg_item_title">
          <span>小肠吸收</span><span>{{ smallAbsorbIndex }}分</span><span>/100</span><span>{{ smallAbsorb }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="xiaochangxishou" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from '@/pages/healthy/view_bottom'
import axios from 'axios'

export default {
  name: 'ReportB',
  components: { view_bottom },
  data() {
    return {
      enzyme: '',
      enzymeIndex: '',
      peristalsis: '',
      peristalsisIndex: '',
      absorb: '',
      absorbIndex: '',
      smallPeristalsis: '',
      smallPeristalsisIndex: '',
      smallAbsorb: '',
      smallAbsorbIndex: '',
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/getStomachs?id=${id}`
      this.br = '<br/>'
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.enzyme = result.data.enzyme
            this.enzymeIndex = result.data.enzymeIndex
            this.peristalsis = result.data.peristalsis
            this.peristalsisIndex = result.data.peristalsisIndex
            this.absorb = result.data.absorb
            this.absorbIndex = result.data.absorbIndex
            this.smallPeristalsis = result.data.smallPeristalsis
            this.smallPeristalsisIndex = result.data.smallPeristalsisIndex
            this.smallAbsorb = result.data.smallAbsorb
            this.smallAbsorbIndex = result.data.smallAbsorbIndex

            this.initBarChart('changmei', this.enzyme)
            this.initBarChart('weirudong', this.peristalsis)
            this.initBarChart('weixishou', this.absorb)
            this.initBarChart('xiaochangrud', this.smallPeristalsis)
            this.initBarChart('xiaochangxishou', this.smallAbsorb)
          }).catch(error => {
        //this.$router.replace("error")
      })
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },

        },
        yAxis: {
          type: 'category',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    { offset: 0, color: '#dc6666' },                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    { offset: 1, color: '#dc6666' },                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      }
      jiankang.setOption(option)
    },
    openValue(info) {
      //this.$router.push({ path: '/Healthy/Introduce', query: { userId: info, date: new Date().getTime() } })
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: #f14848;
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: #dc6666;
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
