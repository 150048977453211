<template>
  <div></div>
</template>

<script>
import axios from "axios";

export default {
  name: "Index",
  created() {
    let geturl = window.location.href
    let getqyinfo = geturl.split('?')[1]
    console.log(getqyinfo)
    this.getcode(getqyinfo)
  },
  methods: {
    getcode(getqyinfo) {
      console.log("getcode")
      const code = this.getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      console.log(code)
      const local = location.protocol + '//' + location.host + "/?" + getqyinfo;
      console.log(local)
      let redirect_uri = encodeURIComponent(local) //回调的地址要编码
      console.log(redirect_uri)
      if (code == null || code === '') {
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb049aecfc2f2b9d5&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        console.log(code)
        axios.post(`${this.baseUrl}/app/wxCode/?`+getqyinfo, {
          body: code
        }).then((result) => {
          const userid = result.data
          console.log(result);
          console.log('请求成功’');
          console.log(userid);
          if (userid == null || userid === "" || userid === '') {
            //this.$router.replace("error")
          } else {
            console.log('请求成功’');
            this.$router.replace({name: 'Login', query: {userId: userid}});
          }
        }).catch(error => {
          //this.$router.replace("error")
        })
      }
    },
    getUrlParam(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
  }
}
</script>

<style scoped>

</style>
