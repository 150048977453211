<template>
  <div>
    <div style="height: 10vh;background: #e9c7a0;display: flex">
      <div
          style="margin-top: 3.5vh;margin-left: 3vw;height: 3vh;display: flex;align-items: center;"
          @click="$router.back()">
        <img v-if="$route.query && $route.query.showTitle" src="../../assets/images/back.png"
             style="width: 3vh;height: 3vh">
        <span style="font-size: 3vh;font-weight: 700;color: #dc6666;text-align: left;vertical-align: top;">{{
            id || ''
          }}</span>
      </div>
    </div>
    <div class="viewBg" v-html="info"></div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Introduce',
  data() {
    return {
      info: '',
      id: ''
    }
  },
  created() {
    // document.title = '舌诊评估'
    console.log(this.$route.query)
    this.id = this.$route.query.id
    this.getcode(this.id)
  },
  methods: {
    getcode(id) {
      let url = `${this.baseUrl}/app/introduce?title=${id}`
      this.br = '<br/>'
      axios.get(url)
      axios.get(url)
          .then((result) => {
            console.log(result)
            this.info = result.data.info
          }).catch(error => {
        //this.$router.replace("error")
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    // 销毁组件，避免通过vue-router再次进入时，仍是上次的history缓存的状态
    this.$destroy(true)
    next()
  }
}
</script>

<style scoped>
.viewBg {
  background: rgba(255, 255, 255, 1);
  margin: 50px 32px 100px;
}
</style>
